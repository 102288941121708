
import { defineComponent, ref } from 'vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'

export default defineComponent({
  components: { TmFormLine },
  setup() {
    const primaryField = ref('john.doe@company.com')

    return {
      primaryField,
    }
  },
})
