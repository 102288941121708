
import type { PropType } from 'vue'
import { computed, defineComponent, ref, toRef } from 'vue'
import TmPanel from '@/components/shared/tmPanel/TmPanel.vue'
import ComposeSideButtonInsertTag from '@/components/shared/compose/sideButtons/ComposeSideButtonInsertTag.vue'
import type { WebWidgetPanels } from '@/definitions/chats/settings/web-widget/types'
import TmButton from '@/components/shared/TmButton.vue'

const tagList = [
  'First name',
  'Last name',
  'Contact email',
]

export default defineComponent({
  components: {
    TmButton,
    ComposeSideButtonInsertTag,
    TmPanel,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    btnText: {
      type: String,
      default: '',
    },
    showPanels: {
      type: String as PropType<WebWidgetPanels>,
    },
    showTags: {
      type: Boolean,
    },
  },
  setup(props) {
    const primaryField = toRef(props, 'title')
    const secondaryField = toRef(props, 'description')
    const buttonLabel = toRef(props, 'btnText')
    const fieldsEmail = ref([
      {
        label: 'Name',
        value: false,
        disabled: false,
      },
      {
        label: 'Email',
        value: true,
        disabled: true,
      },
      {
        label: 'Message',
        value: false,
        disabled: false,
      },
    ])
    const fieldsPhone = ref([
      {
        label: 'Name',
        value: false,
        disabled: false,
      },
      {
        label: 'Your mobile number',
        value: true,
        disabled: true,
      },
      {
        label: 'Message',
        value: true,
        disabled: true,
      },
    ])

    const fields = computed(() => {
      const fieldsMap = {
        email: fieldsEmail,
        phone: fieldsPhone,
      }
      if (props.showPanels) {
        return fieldsMap[props.showPanels].value
      }
      return false
    })

    return {
      primaryField,
      secondaryField,
      fields,
      buttonLabel,
      tagList,
    }
  },
})
