
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import TmCountry from '@/components/shared/TmCountry.vue'
import TmBadge from '@/components/shared/TmBadge.vue'
import WhiteBlock from '@/components/shared/templates/WhiteBlock.vue'
import type { WebWidgetMainSection } from '@/definitions/chats/settings/web-widget/types'
import {
  webWidgetDevicesOptions,
  webWidgetPageUrlsOptions,
  webWidgetCountriesOptions,
  webWidgetScheduleOptions
} from '@/definitions/chats/settings/web-widget/data'
import WidgetTargetingUrlsPreview
  from '@/components/views/chats/webWidget/targeting/WidgetTargetingUrlsPreview.vue'
import WidgetTargetingPreviewItem
  from '@/components/views/chats/webWidget/targeting/WidgetTargetingPreviewItem.vue'

export default defineComponent({
  components: {
    WidgetTargetingPreviewItem,
    WidgetTargetingUrlsPreview,
    WhiteBlock,
    TmBadge,
    TmCountry,
  },
  props: {
    targetingItem: {
      type: Object as PropType<WebWidgetMainSection>,
      required: true,
    },
  },
  setup() {
    return {
      webWidgetCountriesOptions,
      webWidgetScheduleOptions,
      webWidgetPageUrlsOptions,
      webWidgetDevicesOptions,
    }
  },
})
