
import { defineComponent } from 'vue'
import { webWidgetDevicesOptions } from '@/definitions/chats/settings/web-widget/data'

export default defineComponent({
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      webWidgetDevicesOptions,
    }
  },
})
