
import { defineComponent, ref } from 'vue'
export default defineComponent({
  setup() {
    const field = ref('Customers 1')

    return {
      field,
    }
  },
})
