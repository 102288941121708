import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5957b972"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "widget-targeting-preview__item" }
const _hoisted_2 = { class: "widget-targeting-preview__item-header" }
const _hoisted_3 = { class: "widget-targeting-preview__item-title" }
const _hoisted_4 = { class: "widget-targeting-preview__item-body" }
const _hoisted_5 = { class: "widget-targeting-preview__item-text-value" }
const _hoisted_6 = {
  key: 0,
  class: "widget-targeting-preview__item-selected-value"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_tm_icon, {
        name: _ctx.icon,
        size: "large",
        class: "primary--text mr-2"
      }, null, 8, ["name"]),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.title), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _renderSlot(_ctx.$slots, "text-value", {}, undefined, true)
      ]),
      (!!_ctx.$slots['default'])
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}