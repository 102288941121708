
import { defineComponent, ref } from 'vue'
import EmbedCode from '@/components/shared/templates/EmbedCode.vue'
import CodeTag from '@/components/shared/templates/CodeTag.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import { copyToClipboard } from '@/services/utils'
import { useChatsSettings } from '@/compositions/chats/chatsSettings'
import { useModals } from '@/compositions/modals'

export default defineComponent({
  components: {
    TmTooltip,
    TmButton,
    CodeTag,
    EmbedCode,
  },
  setup() {
    const { openModal } = useModals()
    const copied = ref(false)
    const copyFunc = (text: string) => {
      copied.value = true
      copyToClipboard(text)
    }
    const code = '<script type=“text/javascript”> const script = document.createElement(“script”); script.src = “https://widgets.touchpointtesting.com/widget-script.js”; script.type = “text/javascript”; script.async = true; window[“__tp-widget-id__“] = '

    const { openTestWidgetPage } = useChatsSettings()

    return {
      code,
      copyFunc,
      copied,
      openTestWidgetPage,
      openModal,
    }
  },
})
