import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_expansion = _resolveComponent("tm-expansion")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sections, (section, key) => {
      return (_openBlock(), _createBlock(_component_tm_expansion, {
        key: section.label,
        modelValue: section.opened,
        "onUpdate:modelValue": ($event: any) => ((section.opened) = $event),
        label: section.label,
        group: "chatWidgetCreateAppearance",
        icon: section.icon,
        "icon-class": "primary--text mr-4",
        "icon-size": "xxxLarge",
        "hidable-btn": "",
        size: "small",
        "header-class": "chat-expansion__label",
        class: _normalizeClass(["chat-expansion", [
        { 'chat-expansion--opened': section.opened },
        { 'mb-2': key !== _ctx.sections.length - 1 },
      ]])
      }, {
        default: _withCtx(() => [
          (section.componentName)
            ? (_openBlock(), _createBlock(_resolveDynamicComponent(section.componentName), { key: 0 }))
            : _createCommentVNode("", true)
        ]),
        _: 2
      }, 1032, ["modelValue", "onUpdate:modelValue", "label", "icon", "class"]))
    }), 128))
  ]))
}