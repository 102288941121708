
import { defineComponent } from 'vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import useWidget from '@/compositions/chatWidget'

export default defineComponent({
  components: { TmFormLine },
  setup() {
    const { widgetAppearanceSettings } = useWidget()

    return {
      widgetAppearanceSettings,
    }
  },
})
