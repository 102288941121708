
import type { PropType } from 'vue'
import { defineComponent, ref } from 'vue'
import type { WebWidgetPanels } from '@/definitions/chats/settings/web-widget/types'
import WidgetChannelsForm from '@/components/views/chats/webWidget/channels/WidgetChannelsForm.vue'

export default defineComponent({
  components: {
    WidgetChannelsForm,
  },
  props: {
    btnText: {
      type: String,
      default: '',
    },
    showPanels: {
      type: String as PropType<WebWidgetPanels>,
    },
    showTags: {
      type: Boolean,
    },
  },
  setup() {
    const secondaryField = ref('Text this number or fill in the form and we will reply to you as soon as possible.')

    return {
      secondaryField,
    }
  },
})
