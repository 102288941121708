
import { defineComponent, ref, watch } from 'vue'
import TmExpansion from '@/components/shared/TmExpansion.vue'
import WidgetColor
  from '@/components/views/chats/webWidget/appearance/WidgetColor.vue'
import WidgetMinimizedState from '@/components/views/chats/webWidget/appearance/WidgetMinimizedState.vue'
import WidgetWelcomeScreen from '@/components/views/chats/webWidget/appearance/WidgetWelcomeScreen.vue'
import useWidget from '@/compositions/chatWidget'
import type { WebWidgetSubSection } from '@/definitions/chats/settings/web-widget/types'

export default defineComponent({
  components: { WidgetWelcomeScreen, WidgetMinimizedState, WidgetColor, TmExpansion },
  props: {
    isOpen: {
      type: Boolean,
    },
  },
  setup(props) {
    const {
      settingsSections,
      widgetSettingsUpdateState,
      updateCurrentState,
    } = useWidget()

    const sections = ref<WebWidgetSubSection[]>([
      {
        label: 'Color theme',
        opened: true,
        icon: 'palette',
        componentName: 'widget-color',
        widgetState: 'welcome',
      },
      {
        label: 'Minimized state',
        opened: false,
        icon: 'tmi-photo-size-select-small-flip',
        componentName: 'widget-minimized-state',
        hiddenWidget: true,
      },
      {
        label: 'Welcome screen',
        opened: false,
        icon: 'web_asset',
        componentName: 'widget-welcome-screen',
        widgetState: 'welcome',
      },
    ])

    widgetSettingsUpdateState(sections.value, 'welcome')

    watch(() => props.isOpen, (newVal) => {
      if (newVal) {
        widgetSettingsUpdateState(sections.value, 'welcome')
      } else {
        settingsSections.value = []
        updateCurrentState('welcome')
      }
    })

    return {
      sections,
    }
  },
})
