import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_auto_size_panel_item_button = _resolveComponent("tm-auto-size-panel-item-button")!
  const _component_details_hero = _resolveComponent("details-hero")!

  return (_openBlock(), _createBlock(_component_details_hero, {
    title: "MessageMagnet",
    class: "mb-4",
    icon: "tmi-testimonial-comment",
    "max-visible-buttons": 3
  }, {
    right: _withCtx(() => [
      _createVNode(_component_tm_auto_size_panel_item_button, {
        icon: "tmi-duplicate",
        text: "Copy embed code"
      }),
      _createVNode(_component_tm_auto_size_panel_item_button, {
        icon: "open_in_new",
        text: "View test page",
        onClick: _ctx.openTestWidgetPage
      }, null, 8, ["onClick"]),
      _createVNode(_component_tm_auto_size_panel_item_button, {
        icon: "edit",
        text: "Edit details",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openModal('webWidgetEdit')))
      }),
      _createVNode(_component_tm_auto_size_panel_item_button, {
        icon: "block",
        text: "Deactivate",
        onClick: _ctx.openDeactivateWidgetModal
      }, null, 8, ["onClick"]),
      _createVNode(_component_tm_auto_size_panel_item_button, {
        icon: "delete",
        text: "Delete",
        onClick: _ctx.openDeleteWidgetModal
      }, null, 8, ["onClick"])
    ]),
    _: 1
  }))
}