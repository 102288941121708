
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

type webWidgetPageUrls = {
  rule: string;
  value: string;
}

export default defineComponent({
  props: {
    items: {
      type: Array as PropType<webWidgetPageUrls[]>,
      default: () => [],
    },
  },
})
