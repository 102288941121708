
import { defineComponent, ref, watch } from 'vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import TmButton from '@/components/shared/TmButton.vue'
import useWidget from '@/compositions/chatWidget'

export default defineComponent({
  components: {
    TmButton,
    TmFormLine,
  },
  setup() {
    const { updateCurrentState } = useWidget()
    const whatsapp = ref('- Select a WhatsApp number - ')
    const customNumber = ref()
    const options = [
      { subheader: 'WhatsApp numbers', disabled: true },
      { number: '+1 345 434 7654', name: 'Nestle USA' },
      { number: '+1 345 555 7665', name: 'Nestle USA' },
      { number: '+44 7355 577146', name: 'Nestle Canada' },
    ]
    const showCustomNumber = ref(false)
    const handleCustomNumber = () => {
      whatsapp.value = 'Display custom number'
      showCustomNumber.value = true
    }
    watch(() => whatsapp.value, () => {
      updateCurrentState('whatsapp')
    })

    return {
      customNumber,
      whatsapp,
      handleCustomNumber,
      showCustomNumber,
      options,
    }
  },
})
