
import { watch, defineComponent, ref } from 'vue'
import TmColorPalette from '@/components/shared/TmColorPalette.vue'
import type { ColorPaletteType } from '@/definitions/_general/_types/colorPalette'
import { colorPaletteOptionsFilled } from '@/definitions/_general/_data/colorPaletteOptions'
import useWidget from '@/compositions/chatWidget'

export default defineComponent({
  components: { TmColorPalette },
  setup() {
    const { widgetAppearanceSettings } = useWidget()
    const colorTheme = ref('gradient')
    const color = ref<ColorPaletteType>(colorPaletteOptionsFilled[0])

    const checkGradient = (val: boolean) => {
      widgetAppearanceSettings.isGradient = val
    }

    watch(() => colorTheme.value, (val) => {
      checkGradient(val === 'gradient')
    })

    watch(() => color.value, (val) => {
      widgetAppearanceSettings.primaryColor = val.color
    })

    return {
      colorTheme,
      color,
      colorPaletteOptionsFilled,
      widgetAppearanceSettings,
    }
  },
})
