
import { defineComponent, markRaw, ref, watch } from 'vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import TmCountry from '@/components/shared/TmCountry.vue'
import { widgetNumberOptions } from '@/definitions/_general/_data/optionsList'
import phoneCountryVariant, { phoneCountryWithCodeVariant } from '@/definitions/_general/_data/countryVariant'
import FieldSearchSelect from '@/components/shared/field/FieldSearchSelect.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import useWidget from '@/compositions/chatWidget'
import WidgetNoNumberAlert
  from '@/components/views/chats/webWidget/channels/WidgetNoNumberAlert.vue'
import { useBreakpoints } from '@/compositions/breakpoints'

const globalFlag = {
  name: 'Global',
  id: 'global',
  code: '',
  customIcon: 'images/customFlags/globalIcon.png',
}

export default defineComponent({
  components: {
    TmDropdownItem,
    TmDropdown,
    TmButton,
    FieldSearchSelect,
    TmCountry,
    TmFormLine,
  },
  inheritAttrs: false,
  props: {
    noOwnNumbers: {
      type: Boolean,
    },
  },
  setup() {
    const { alertComponent } = useWidget()
    const { isSmMax } = useBreakpoints()
    const customFlags = ref([
      globalFlag,
      ...phoneCountryWithCodeVariant,
    ])

    const defaultItem = ref({
      country: customFlags.value[0],
      phone: widgetNumberOptions.find((item: any) => item?.label === 'None'),
      ownNumber: '+',
    })

    const data = ref<any>([
      { ...defaultItem.value },
    ])

    const setNumber = (key: string) => {
      const numberId = widgetNumberOptions.find((number: any) => number?.id === data.value[key]?.country.id)
      data.value[key].phone = numberId || widgetNumberOptions.find(item => item?.label === 'None')
    }

    const setCustomPhone = (item: any, key: string) => {
      item.phone = { label: 'Display custom number' }
      const code = phoneCountryWithCodeVariant.find((country: any) => country?.id === item?.country?.id)
      data.value[key].ownNumber = code ? `${code?.code}`.replace(/[{()}]/g, '') : '+1'
    }

    const addNewItem = (flagItem = customFlags.value[0]) => {
      const currPhone = widgetNumberOptions.find((item: any) => item.id === flagItem?.id)
      data.value.push({
        ...defaultItem.value,
        country: flagItem,
        phone: currPhone || defaultItem.value.phone,
      })
    }

    const getFlagCountryById = (id: string) => {
      return phoneCountryVariant.find((item: any) => item.id === id)
    }

    const deleteItem = (index: number) => {
      data.value.splice(index, 1)
    }

    const checkAnyNumberEmpty = () => {
      const notEmptyField = data.value.find((item: any) => item.phone.label !== 'None')
      if (notEmptyField) {
        alertComponent.value = null
      } else {
        alertComponent.value = markRaw(WidgetNoNumberAlert)
      }
    }

    checkAnyNumberEmpty()
    watch(data.value, () => {
      checkAnyNumberEmpty()
    })

    return {
      globalFlag,
      customFlags,
      widgetNumberOptions,
      addNewItem,
      data,
      deleteItem,
      getFlagCountryById,
      setNumber,
      setCustomPhone,
      alertComponent,
      isSmMax,
    }
  },
})
