
import { defineComponent, toRef } from 'vue'
import TmAvatar from '@/components/shared/TmAvatar.vue'
import { useBreakpoints } from '@/compositions/breakpoints'

export default defineComponent({
  components: {
    TmAvatar,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    image: {
      type: String,
    },
    icon: {
      type: String,
    },
  },
  setup(props) {
    const { isSmMax } = useBreakpoints()
    const primaryFieldVal = toRef(props, 'title')
    const secondaryFieldVal = toRef(props, 'description')

    return {
      primaryFieldVal,
      secondaryFieldVal,
      isSmMax,
    }
  },
})
