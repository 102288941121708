
import { computed, defineComponent, ref, watch } from 'vue'
import ChatsSettingsPage from '@/components/pages/chats/settings/ChatsSettingsPage.vue'
import TmExpansion from '@/components/shared/TmExpansion.vue'
import WidgetHero from '@/components/views/chats/webWidget/WidgetHero.vue'
import WhiteBlock from '@/components/shared/templates/WhiteBlock.vue'
import TmAvatar from '@/components/shared/TmAvatar.vue'
import WidgetAppearance from '@/components/views/chats/webWidget/appearance/WidgetAppearance.vue'
import WidgetChannels from '@/components/views/chats/webWidget/channels/WidgetChannels.vue'
import WidgetTargeting from '@/components/views/chats/webWidget/targeting/WidgetTargeting.vue'
import WidgetEmbedCode from '@/components/views/chats/webWidget/WidgetEmbedCode.vue'
import ChattingBlock from '@/components/pages/chattingWidget/ChattingBlock.vue'
import TmButton from '@/components/shared/TmButton.vue'
import ChattingWidget from '@/components/pages/chattingWidget/ChattingWidget.vue'
import useWidget from '@/compositions/chatWidget'
import type { WebWidgetMainSection } from '@/definitions/chats/settings/web-widget/types'
import {
  webWidgetPageUrlsDefaultOption,
  webWidgetCountriesOptions,
  webWidgetSchedule,
  webWidgetScheduleOptions
} from '@/definitions/chats/settings/web-widget/data'
import TmCountry from '@/components/shared/TmCountry.vue'
import TmBadge from '@/components/shared/TmBadge.vue'
import { utcZonesOptions } from '@/definitions/_general/_data/utc'
import WidgetTargetingPreview
  from '@/components/views/chats/webWidget/targeting/WidgetTargetingPreview.vue'
import TmAlert from '@/components/shared/TmAlert.vue'

export default defineComponent({
  components: {
    TmAlert,
    WidgetTargetingPreview,
    TmBadge,
    TmCountry,
    ChattingWidget,
    TmButton,
    ChattingBlock,
    WidgetAppearance,
    WidgetChannels,
    WidgetTargeting,
    TmAvatar,
    WhiteBlock,
    WidgetHero,
    TmExpansion,
    ChatsSettingsPage,
    WidgetEmbedCode,
  },
  setup() {
    const breadcrumbs = [
      { label: 'Messenger', url: { name: 'base.chats' } },
      { label: 'Settings', url: { name: 'base.chats.settings' } },
      { label: 'Web widgets', url: { name: 'base.chats.settings.webWidgets' } },
      { label: 'MessageMagnet' },
    ]

    const sections = ref<WebWidgetMainSection[]>([
      {
        label: 'Appearance',
        caption: 'Customize how your website widget will look and feel.',
        opened: true,
        icon: 'brush',
        componentName: 'widget-appearance',
      },
      {
        label: 'Channels',
        caption: 'Set up communication channels available for your visitors.',
        opened: false,
        icon: 'all_inbox',
        componentName: 'widget-channels',
      },
      {
        label: 'Targeting',
        caption: 'Select the target audience - who should see the widget.',
        opened: false,
        icon: 'tmi-target',
        componentName: 'widget-targeting',
        modelValue: {
          countries: {
            value: 'all',
            countriesList: [],
          },
          schedule: {
            value: 'all',
            timezone: utcZonesOptions[16],
            weekSchedule: webWidgetSchedule,
          },
          pageUrls: {
            value: 'all',
            urlsList: [{ ...webWidgetPageUrlsDefaultOption }],
          },
          devices: {
            value: 'all',
          },
        },
      },
      {
        label: 'Embed code',
        caption: 'Embed the online widget into your website.',
        opened: false,
        icon: 'code',
        componentName: 'widget-embed-code',
      },
    ])

    const activeSection = computed(() => sections.value.find(item => item.opened))
    const targetingItem = computed(() => sections.value.find(item => item.label === 'Targeting'))
    const isTargeting = computed(() => sections.value.find(item => item.label === 'Targeting')?.opened)

    const {
      chatHidden,
      bindChat,
      updateCurrentState,
      alertComponent,
    } = useWidget()

    bindChat(true)

    watch(sections.value, () => {
      if (!activeSection.value) {
        bindChat(true)
        updateCurrentState('welcome')
      }
    })

    return {
      breadcrumbs,
      sections,
      chatHidden,
      targetingItem,
      isTargeting,
      webWidgetCountriesOptions,
      webWidgetScheduleOptions,
      alertComponent,
    }
  },
})
