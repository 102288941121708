
import { watch, defineComponent, ref } from 'vue'
import { webWidgetCountriesOptions } from '@/definitions/chats/settings/web-widget/data'

export default defineComponent({
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const value = ref('all')
    const countriesList = ref([])
    const countriesHiddenList = ref([])

    watch(() => props.modelValue.countries.value, () => {
      props.modelValue.countries.countriesList = []
    })

    return {
      value,
      countriesList,
      countriesHiddenList,
      webWidgetCountriesOptions,
    }
  },
})
