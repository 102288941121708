import { markRaw } from 'vue'
import WidgetChannelsWelcomeScreen
  from '@/components/views/chats/webWidget/channels/WidgetWelcomeScreen.vue'
import WidgetChannelsForm from '@/components/views/chats/webWidget/channels/WidgetChannelsForm.vue'
import WidgetChannelsQueueSettings
  from '@/components/views/chats/webWidget/channels/WidgetQueueSettings.vue'
import WidgetChannelsSatisfactionSurvey
  from '@/components/views/chats/webWidget/channels/WidgetSatisfactionSurvey.vue'
import WidgetChannelsEmail from '@/components/views/chats/webWidget/channels/WidgetChannelsEmail.vue'
import WidgetChannelsLocalization
  from '@/components/views/chats/webWidget/channels/WidgetChannelsLocalization.vue'
import WidgetChannelsFormTextMessage
  from '@/components/views/chats/webWidget/channels/WidgetChannelsFormMessage.vue'
import WidgetChannelsAdditionalSettings
  from '@/components/views/chats/webWidget/channels/WidgetChannelsAdditionalSettings.vue'
import type { DaySchedule } from '@/definitions/shared/types'
import type {
  PreProps,
  RatePopoverFeedback,
  WebWidgetSection,
  WebWidgetTargetingOption,
  WidgetMessagesProps,
  WebWidgetsTableItem
} from '@/definitions/chats/settings/web-widget/types'
import WidgetWhatsappNumber from '@/components/views/chats/webWidget/channels/WidgetWhatsappNumber.vue'

export const ratePopoverFeedbackGood: RatePopoverFeedback = {
  class: 'rate-status--good',
  icon: 'thumb_up',
  name: 'Good',
  title: 'We are glad you liked it',
  label: 'Tell us where we did good',
  placeholder: 'What did you like about this chat',
}

export const ratePopoverFeedbackBad: RatePopoverFeedback = {
  class: 'rate-status--bad',
  icon: 'thumb_down',
  name: 'Poor',
  title: 'We\'re sorry to hear that',
  label: 'Tell us how we can improve',
  placeholder: 'What did you dislike about this chat',
}

export const preChatProps: PreProps = {
  containerTitle: 'Chat now',
  containerSubtitle: 'Agents are online',
  title: 'Chat with our agent',
  subtitle: 'Please fill the details below to start the conversation with our agent.',
  btnText: 'Start chat',
}

export const preEmailProps: PreProps = {
  containerTitle: 'Send an email',
  containerSubtitle: 'Create a support ticket',
  title: 'Get response via email',
  subtitle: 'Please fill the details below and we will reply to you via email within 72 hours.',
  btnText: 'Send email',
}

export const preEmailSuccessProps: PreProps = {
  containerTitle: 'Send an email',
  containerSubtitle: 'Create a support ticket',
  title: 'We have received your email',
  text: 'We will respond to your email shortly:',
}

export const preTextProps: PreProps = {
  containerTitle: 'Send a text',
  containerSubtitle: 'Get help via a text message',
  title: 'Get response via SMS',
  subtitle: 'Please describe your issue and we will shortly reply to you via a text message.',
  btnText: 'Request help via SMS',
  phoneForm: true,
}

export const preTextSuccessProps: PreProps = {
  containerTitle: 'Send a text',
  containerSubtitle: 'Get help via a text message',
  title: 'We have received your text',
  text: 'We will respond to you with a text message to:',
}
export const chatWaitingProps: WidgetMessagesProps = {
  permanentMode: 'waiting',
}
export const chatMessagesOnlyProps: WidgetMessagesProps = {
  permanentMode: 'messages-only',
}

const onlineChat: WebWidgetSection[] = [
  {
    title: 'Welcome screen',
    icon: 'tmi-hand-wave',
    componentName: markRaw(WidgetChannelsWelcomeScreen),
    componentProps: {
      icon: 'tmi-message-bubble',
      title: 'Chat now',
      description: 'Get instant help via chat',
    },
    widgetView: 'welcome',
  },
  {
    title: 'Pre-chat form',
    icon: 'tmi-form',
    componentName: markRaw(WidgetChannelsForm),
    componentProps: {
      title: 'Chat with our agent',
      description: 'Please fill the details below to start the conversation with our agent.',
      btnText: 'Start chat',
      showPanels: 'email',
    },
    widgetView: 'preChat',
  },
  {
    title: 'Queue settings',
    icon: 'history',
    componentName: markRaw(WidgetChannelsQueueSettings),
    widgetView: 'chatWaiting',
  },
  {
    title: 'Satisfaction survey',
    icon: 'thumb_up',
    componentName: markRaw(WidgetChannelsSatisfactionSurvey),
    widgetView: 'chatMessagesOnly',
  },
]
const email: WebWidgetSection[] = [
  {
    title: 'Welcome screen',
    icon: 'tmi-hand-wave',
    componentName: markRaw(WidgetChannelsWelcomeScreen),
    componentProps: {
      icon: 'email',
      title: 'Send an email',
      description: 'Create a support ticket',
    },
    widgetView: 'welcome',
  },
  {
    title: 'Ticket form',
    icon: 'tmi-form',
    componentName: markRaw(WidgetChannelsForm),
    componentProps: {
      title: 'Get response via email',
      description: 'Please fill the details below and we will reply to you via email within 72 hours.',
      btnText: 'Send email',
      showPanels: 'email',
    },
    widgetView: 'email',
  },
  {
    title: 'Success screen',
    icon: 'check_circle_outline',
    componentName: markRaw(WidgetChannelsForm),
    componentProps: {
      title: 'We have received your email',
      description: 'We will respond to your email shortly:',
      showTags: true,
    },
    widgetView: 'emailSuccess',
  },
  {
    title: 'Associated email',
    icon: 'email',
    componentName: markRaw(WidgetChannelsEmail),
    widgetView: 'welcome',
  },
]
const call: WebWidgetSection[] = [
  {
    title: 'Welcome screen',
    icon: 'tmi-hand-wave',
    componentName: markRaw(WidgetChannelsWelcomeScreen),
    componentProps: {
      icon: 'phone',
      title: 'Make a call',
      description: 'Speak with our agent now',
    },
    widgetView: 'welcome',
  },
  {
    title: 'Call us screen',
    icon: 'tmi-form',
    componentName: markRaw(WidgetChannelsForm),
    componentProps: {
      title: 'Speak with an agent',
      description: 'Have an urgent matter? Please call us, and a dedicated agent will be available to help you.',
      btnText: 'Call now',
    },
    widgetView: 'makeCall',
  },
  {
    title: 'Numbers and countries',
    icon: 'public',
    componentName: markRaw(WidgetChannelsLocalization),
    widgetView: 'makeCall',
  },
]
const textMessage: WebWidgetSection[] = [
  {
    title: 'Welcome screen',
    icon: 'tmi-hand-wave',
    componentName: markRaw(WidgetChannelsWelcomeScreen),
    componentProps: {
      icon: 'chat',
      title: 'Send a text message',
      description: 'Request help via SMS',
    },
    widgetView: 'welcome',
  },
  {
    title: 'Form fields',
    icon: 'tmi-form',
    componentName: markRaw(WidgetChannelsFormTextMessage),
    componentProps: {
      btnText: 'Request help via SMS',
      showPanels: 'phone',
    },
    widgetView: 'preText',
  },
  {
    title: 'Success screen',
    icon: 'check_circle_outline',
    componentName: markRaw(WidgetChannelsForm),
    componentProps: {
      title: 'We have received your text',
      description: 'We will respond to your email shortly:',
      showTags: true,
    },
    widgetView: 'preTextSuccess',
  },
  {
    title: 'Numbers and countries',
    icon: 'public',
    componentName: markRaw(WidgetChannelsLocalization),
    componentProps: {
      noOwnNumbers: true,
    },
    widgetView: 'preChat',
  },
  {
    title: 'Additional settings',
    icon: 'tmi-settings-outline',
    componentName: markRaw(WidgetChannelsAdditionalSettings),
    widgetView: 'preChat',
  },
]

const whatsapp: WebWidgetSection[] = [
  {
    title: 'Welcome screen',
    icon: 'tmi-hand-wave',
    componentName: markRaw(WidgetChannelsWelcomeScreen),
    componentProps: {
      image: require('@/assets/images/chats/whatsapp.svg'),
      title: 'Chat on WhatsApp',
      description: 'Request help via WhatApp chat',
    },
    widgetView: 'welcome',
  },
  {
    title: 'Message us screen',
    icon: 'tmi-form',
    componentName: markRaw(WidgetChannelsForm),
    componentProps: {
      title: 'Message us on WhatsApp',
      description: 'Сlick the button below or scan the QR code to send a message to this WhatsApp number.',
      btnText: 'Open chat',
    },
    widgetView: 'whatsapp',
  },
  {
    title: 'WhatsApp number',
    icon: 'public',
    componentName: markRaw(WidgetWhatsappNumber),
    widgetView: 'whatsappDisabled',
  },
]

export const webWidgetData = {
  onlineChat,
  email,
  call,
  whatsapp,
  textMessage,
}

export const webWidgetCountriesOptions: WebWidgetTargetingOption = {
  all: 'Show in all countries',
  list: 'Show only in these countries',
  hiddenList: 'Hide only in these countries',
}
export const webWidgetScheduleOptions: WebWidgetTargetingOption = {
  all: 'Always',
  specific: 'Custom schedule',
}
export const webWidgetPageUrlsOptions: WebWidgetTargetingOption = {
  all: 'Show on all pages',
  specific: 'Show on specific pages only',
  specificNot: 'Hide on specific pages only',
}

export const webWidgetPageUrlsDefaultOption = { rule: 'URL contains', value: '' }

export const webWidgetDevicesOptions: WebWidgetTargetingOption = {
  all: 'Show on all devices',
  desktop: 'Desktop devices only',
  mobile: 'Mobile devices only',
}

export const webWidgetSchedule: DaySchedule[] = [
  {
    day: 'monday',
    active: true,
    schedule: [{ startTime: '8:00 AM', endTime: '9:00 AM' }],
  },
  {
    day: 'tuesday',
    active: true,
    schedule: [{ startTime: '8:00 AM', endTime: '9:00 AM' }],
  },
  {
    day: 'wednesday',
    active: true,
    schedule: [{ startTime: '8:00 AM', endTime: '9:00 AM' }],
  },
  {
    day: 'thursday',
    active: true,
    schedule: [{ startTime: '8:00 AM', endTime: '9:00 AM' }],
  },
  {
    day: 'friday',
    active: true,
    schedule: [{ startTime: '8:00 AM', endTime: '9:00 AM' }],
  },
  {
    day: 'saturday',
    active: false,
    schedule: [{ startTime: '8:00 AM', endTime: '9:00 AM' }],
  },
  {
    day: 'sunday',
    active: false,
    schedule: [{ startTime: '8:00 AM', endTime: '9:00 AM' }],
  },
]

export const webWidgetsTable: WebWidgetsTableItem[] = [
  {
    id: 1,
    name: 'MessageMagnet',
    code: '<script type=“text/javascript”> const script = document.createElement(“script”); script.src ',
    status: {
      name: 'Active',
      color: 'green',
      outline: true,
    },
    lastUpdated: '2 min ago',
  },
  {
    id: 2,
    name: 'TalkFlow Embeds',
    code: '<script type=“text/javascript”> const script = document.createElement(“script”); script.src ',
    status: {
      name: 'Active',
      color: 'green',
      outline: true,
    },
    lastUpdated: '5 Dec 2021, 4:35 am',
  },
  {
    id: 3,
    name: 'InteractBeam',
    domain: 'interactbeam.com',
    code: '<script type=“text/javascript”> const script = document.createElement(“script”); script.src ',
    status: {
      name: 'Inactive',
      color: 'red',
      outline: true,
    },
    lastUpdated: '15 Mar 2023, 7:15 pm',
  },
]
