
import { defineComponent, ref } from 'vue'
import ComposeSideButtonInsertTag from '@/components/shared/compose/sideButtons/ComposeSideButtonInsertTag.vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import TmButton from '@/components/shared/TmButton.vue'

export default defineComponent({
  components: {
    TmButton,
    TmTooltip,
    ComposeSideButtonInsertTag,
  },
  setup() {
    const primaryTextarea = ref('Thank you, we have received your request.')
    const textField = ref(2)
    const secondaryTextarea = ref('Sorry, all agents are busy at the moment. We will reply to your email {{contact.email}} as soon as possible. ')

    return {
      primaryTextarea,
      textField,
      secondaryTextarea,
    }
  },
})
