
import { defineComponent, markRaw, ref, watch } from 'vue'
import TmExpansion from '@/components/shared/TmExpansion.vue'
import TmVerticalExpansionList from '@/components/shared/tmExpansion/TmVerticalExpansionList.vue'
import type { WebWidgetSubSection } from '@/definitions/chats/settings/web-widget/types'
import { webWidgetData } from '@/definitions/chats/settings/web-widget/data'
import useWidget from '@/compositions/chatWidget'
import WidgetDisabledAlert from '@/components/views/chats/webWidget/channels/WidgetDisabledAlert.vue'
import WidgetChannelsSendMessageEmpty from '@/components/views/chats/webWidget/channels/empty/WidgetChannelsSendMessageEmpty.vue'

export default defineComponent({
  components: {
    TmVerticalExpansionList,
    WidgetChannelsSendMessageEmpty,
    TmExpansion,
  },
  props: {
    isOpen: {
      type: Boolean,
    },
  },
  setup(props) {
    const {
      updateCurrentState,
      settingsSections,
      alertComponent,
    } = useWidget()
    const sections = ref<WebWidgetSubSection[]>([
      {
        label: 'Start an online chat',
        opened: true,
        icon: 'palette',
        options: webWidgetData.onlineChat,
        toggleValue: true,
      },
      {
        label: 'Send an email',
        opened: false,
        icon: 'tmi-photo-size-select-small-flip',
        options: webWidgetData.email,
        toggleValue: true,
      },
      {
        label: 'Make a call',
        opened: false,
        icon: 'web_asset',
        options: webWidgetData.call,
        toggleValue: true,
      },
      {
        label: 'Send a text message',
        opened: false,
        icon: 'web_asset',
        options: webWidgetData.textMessage,
        emptyStateComponent: markRaw(WidgetChannelsSendMessageEmpty),
        toggleValue: true,
      },
      {
        label: 'Chat on WhatsApp',
        icon: 'palette',
        opened: false,
        options: webWidgetData.whatsapp,
        toggleValue: true,
      },
    ])

    const isChannelsDisabled = () => {
      const isChannelsDisabled = !sections.value.find(item => item.toggleValue)
      alertComponent.value = isChannelsDisabled ? markRaw(WidgetDisabledAlert) : null
    }

    watch(() => props.isOpen, (newVal) => {
      if (newVal) {
        settingsSections.value = []
        updateCurrentState('welcome')
        isChannelsDisabled()
      } else {
        alertComponent.value = null
      }
    })

    watch(sections.value, () => {
      isChannelsDisabled()
    })

    return {
      sections,
    }
  },
})
