
import { defineComponent } from 'vue'
import DetailsHero from '@/components/shared/templates/details/DetailsHero.vue'
import TmAutoSizePanelItemButton from '@/components/shared/autoSize/panel/TmAutoSizePanelItemButton.vue'
import { useModals } from '@/compositions/modals'
import { useChatsSettings } from '@/compositions/chats/chatsSettings'

export default defineComponent({
  components: {
    TmAutoSizePanelItemButton,
    DetailsHero,
  },
  setup() {
    const { openModal } = useModals()

    const {
      openDeactivateWidgetModal,
      openDeleteWidgetModal,
      openTestWidgetPage,
    } = useChatsSettings()

    return {
      openModal,
      openDeactivateWidgetModal,
      openDeleteWidgetModal,
      openTestWidgetPage,
    }
  },
})
