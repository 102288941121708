
import { defineComponent, ref } from 'vue'
import TmExpansion from '@/components/shared/TmExpansion.vue'
import WidgetColor
  from '@/components/views/chats/webWidget/appearance/WidgetColor.vue'
import WidgetMinimizedState from '@/components/views/chats/webWidget/appearance/WidgetMinimizedState.vue'
import WidgetWelcomeScreen from '@/components/views/chats/webWidget/appearance/WidgetWelcomeScreen.vue'
import WidgetTargetingCountries
  from '@/components/views/chats/webWidget/targeting/WidgetTargetingCountries.vue'
import WidgetTargetingSchedule
  from '@/components/views/chats/webWidget/targeting/WidgetTargetingSchedule.vue'
import WidgetTargetingUrls from '@/components/views/chats/webWidget/targeting/WidgetTargetingUrls.vue'
import WidgetTargetingDevices
  from '@/components/views/chats/webWidget/targeting/WidgetTargetingDevices.vue'

export default defineComponent({
  components: {
    WidgetWelcomeScreen,
    WidgetMinimizedState,
    WidgetColor,
    TmExpansion,
    WidgetTargetingCountries,
    WidgetTargetingSchedule,
    WidgetTargetingUrls,
    WidgetTargetingDevices,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const sections = ref([
      {
        label: 'Countries',
        opened: false,
        icon: 'public',
        componentName: 'widget-targeting-countries',
      },
      {
        label: 'Schedule',
        opened: false,
        icon: 'schedule',
        componentName: 'widget-targeting-schedule',
      },
      {
        label: 'Page URLs',
        opened: false,
        icon: 'link',
        componentName: 'widget-targeting-urls',
      },
      {
        label: 'Device type',
        opened: false,
        icon: 'devices',
        componentName: 'widget-targeting-devices',
      },
    ])
    return {
      sections,
    }
  },
})
