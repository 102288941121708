
import { defineComponent } from 'vue'
import TmWeekSchedule from '@/components/shared/TmWeekSchedule.vue'
import {
  webWidgetCountriesOptions,
  webWidgetSchedule,
  webWidgetScheduleOptions
} from '@/definitions/chats/settings/web-widget/data'

export default defineComponent({
  components: { TmWeekSchedule },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      webWidgetScheduleOptions,
      webWidgetCountriesOptions,
      webWidgetSchedule,
    }
  },
})
