
import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import { webWidgetPageUrlsDefaultOption } from '@/definitions/chats/settings/web-widget/data'

const options = [
  'URL contains',
  'URL doesn’t contain',
  'URL starts with',
  'URL ends with',
  'URL equals to',
  'URL doesn’t equal to',
]

export default defineComponent({
  components: {
    TmButton,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const addItem = () => {
      props.modelValue.urlsList.push({ ...webWidgetPageUrlsDefaultOption })
    }

    const deleteItem = (index: number) => {
      props.modelValue.urlsList.splice(index, 1)
    }

    return {
      data: props.modelValue.urlsList,
      options,
      addItem,
      deleteItem,
    }
  },
})
