
import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import WidgetTargetingUrlsFields
  from '@/components/views/chats/webWidget/targeting/WidgetTargetingUrlsFields.vue'
import { webWidgetPageUrlsOptions } from '@/definitions/chats/settings/web-widget/data'
import { useModals } from '@/compositions/modals'

export default defineComponent({
  components: { WidgetTargetingUrlsFields, TmButton },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { openModal } = useModals()
    return {
      webWidgetPageUrlsOptions,
      openModal,
    }
  },
})
