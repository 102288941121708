
import { defineComponent, reactive, ref, watch } from 'vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import useWidget from '@/compositions/chatWidget'

export default defineComponent({
  components: { TmFormLine },
  setup() {
    const { widgetAppearanceSettings } = useWidget()
    const buttonText = ref()
    const icons = [
      { label: 'Textmagic', icon: 'tmi-textmagic' },
      { label: 'Chat', icon: 'chat' },
      { label: 'Message', icon: 'tmi-message-bubble' },
      { label: 'Phone', icon: 'phone' },
      { label: 'Email', icon: 'email' },
    ]
    const data = reactive({
      icon: icons[0],
      buttonText: 'Chat with us',
      borderRadius: 40,
      alignTo: 'Right',
      sideSpacing: 32,
      bottomSpacing: 26,
      isEyeCatcherMessage: false,
      messageText: '👋 Hello, how can we help?',
      messageDelay: 7,
    })

    watch(buttonText, (newVal) => {
      widgetAppearanceSettings.buttonText = newVal ? data.buttonText : ''
    })

    watch(() => data.isEyeCatcherMessage, (val) => {
      widgetAppearanceSettings.showTooltip = val
    })

    watch(() => data.buttonText, (newVal) => {
      widgetAppearanceSettings.buttonText = newVal
    })

    return {
      buttonText,
      data,
      icons,
      widgetAppearanceSettings,
    }
  },
})
