import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_vertical_expansion_list = _resolveComponent("tm-vertical-expansion-list")!
  const _component_tm_expansion = _resolveComponent("tm-expansion")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sections, (section, key) => {
      return (_openBlock(), _createBlock(_component_tm_expansion, {
        key: section.label,
        modelValue: section.opened,
        "onUpdate:modelValue": ($event: any) => ((section.opened) = $event),
        "toggle-value": section.toggleValue,
        "onUpdate:toggle-value": ($event: any) => ((section.toggleValue) = $event),
        label: section.label,
        group: "chatWidgetCreateChannels",
        "hidable-btn": "",
        size: "small",
        "header-class": "chat-expansion__label",
        class: _normalizeClass(["chat-expansion", [
        { 'chat-expansion--opened': section.opened },
        { 'mb-2': key !== _ctx.sections.length - 1 },
      ]]),
        draggable: ""
      }, {
        default: _withCtx(() => [
          (!section.toggleValue && section.emptyStateComponent)
            ? (_openBlock(), _createBlock(_resolveDynamicComponent(section.emptyStateComponent), { key: 0 }))
            : (_openBlock(), _createBlock(_component_tm_vertical_expansion_list, {
                key: 1,
                "is-visible": section.opened,
                items: section.options,
                disabled: !section.toggleValue
              }, null, 8, ["is-visible", "items", "disabled"]))
        ]),
        _: 2
      }, 1032, ["modelValue", "onUpdate:modelValue", "toggle-value", "onUpdate:toggle-value", "label", "class"]))
    }), 128))
  ]))
}