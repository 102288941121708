
import { defineComponent, onUnmounted, ref, watch } from 'vue'
import useWidget from '@/compositions/chatWidget'

export default defineComponent({
  props: {
    disabledSection: {
      type: Boolean,
    },
  },
  setup() {
    const modelValue = ref(true)
    const { updateShowPopover, updatePopoverStatus } = useWidget()
    updateShowPopover(true)
    updatePopoverStatus('rate')

    onUnmounted(() => {
      updateShowPopover(false)
    })

    watch(() => modelValue.value, (val) => {
      updateShowPopover(val)
    })

    return {
      modelValue,
    }
  },
})
